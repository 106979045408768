/* Not all of our themes use Bootstrap's rounded corners so make 'em all square */
.fc-event > a {
  border-radius: 0;
}

#calendar > div.fc-toolbar.fc-header-toolbar .fc-toolbar-title {
  color: var(--gray); /* Use Bootstrap's "Muted" */
  font-weight: 400;
}

.fc-event {
  cursor: pointer;
}

.fc-event-main > .fc-event-main-frame {
  display: block;
}

/* PMGR-10570: Some of the styles seen here for .fc-event-time and .fc-event-title are overridden elsewhere,
   conditional on PTSThemeBuilder compilation, and exist as shims in the event that this compilation doesn't happen.
   Hopefully these styles can be pared back after enough time has passed, or automatic compilation is supported
   (see PMGR-10054 for more information). */

.fc-event-time {
  background: black;
  color: white;
  font-weight: bold;
  margin: 0;
}

/* Event title - hidden on small screens */
.fc-event-title {
  display: none;
  white-space: normal;
  background: black;
  color: white;
  width: 100%;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .fc-event-title {
    display: block;
    overflow: hidden;
  }
}