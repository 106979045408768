.pts-custom-content p {
  /* Match Bootstrap's mb-1 spacing utility. https://getbootstrap.com/docs/4.0/utilities/spacing/ */
  margin-bottom: .25rem;
}

.pts-custom-content img,
.pts-custom-content iframe {
  max-width: 100%;
}
/* PMGR-9575 Don't apply height: auto to iframes */
.pts-custom-content img {
  height: auto;
}