div#SectionMap {
	overflow: auto;
}

div#SectionMap .section-icon {
    display: block;
}

div#SectionMap .section-group {
    border: 3px solid transparent;
}
div#SectionMap .section-group.selected {
    border: 3px solid red;
}
