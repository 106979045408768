/* Looks like Bootstrap doesn't have a white space property, need this to support our multi-line text fields */
.mobile-ticket-landing .white-space-pre-wrap {
    white-space: pre-wrap;
}

/* When printing */
@media print {
    /* Prevent a single ticket from being split across two pages */
    .mobile-ticket-landing .mobile-ticket {
        page-break-inside: avoid;
    }
    
    /* Force links and ticket separators to black */
    .mobile-ticket-landing a {
        color: black !important;
        text-decoration: none !important;
    }
    
    .mobile-ticket-landing .border-bottom {
        border-bottom-color: black !important;
    }
}