div#SeatMap {
	overflow: auto;
    position: relative;
}

div#SeatMap .map-container {
    position: relative;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

/*
When previewing the seat map during CYO performance selection, this overlay makes it impossible to 
click on any seats and also helps give the impression that this is just a preview.
 */
div#SeatMap .seat-map-preview .seat-map-preview-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
}

div#SeatMap .section-group-box,
div#SeatMap .section-box,
div#SeatMap .stage {
    position: absolute;
}

div#SeatMap .top-left {
    background: url(../../images/stage_up-left.gif) no-repeat;
}
div#SeatMap .top-left.inverted {
  background: url(../../images/stage_down-right.gif) no-repeat;
}
div#SeatMap .top-center {
    background: url(../../images/stage_up.gif) no-repeat;
}
div#SeatMap .top-center.inverted {
  background: url(../../images/stage_down.gif) no-repeat;
}
div#SeatMap .top-right {
    background: url(../../images/stage_up-right.gif) no-repeat;
}
div#SeatMap .top-right.inverted {
  background: url(../../images/stage_down-left.gif) no-repeat;
}
div#SeatMap .mid-left {
    background: url(../../images/stage_left.gif) no-repeat;
}
div#SeatMap .mid-left.inverted {
  background: url(../../images/stage_right.gif) no-repeat;
}
div#SeatMap .mid-right {
    background: url(../../images/stage_right.gif) no-repeat;
}
div#SeatMap .mid-right.inverted {
  background: url(../../images/stage_left.gif) no-repeat;
}
div#SeatMap .bot-left {
    background: url(../../images/stage_down-left.gif) no-repeat;
}
div#SeatMap .bot-left.inverted {
  background: url(../../images/stage_up-right.gif) no-repeat;
}
div#SeatMap .bot-center {
    background: url(../../images/stage_down.gif) no-repeat;
}
div#SeatMap .bot-center.inverted {
  background: url(../../images/stage_up.gif) no-repeat;
}
div#SeatMap .bot-right {
    background: url(../../images/stage_down-right.gif) no-repeat;
}
div#SeatMap .bot-right.inverted {
  background: url(../../images/stage_up-left.gif) no-repeat;
}

div#SeatMap .seat,
div#SeatLegend .seat {
    border: 1px solid black;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    cursor: pointer;
}

/* When previewing the seat map, override the available seat border to make it stand out */
div#SeatMap .seat-map-preview .seat {
    border: 2px solid green;
}

div#SeatMap .seat:hover {
    border: 1px solid red;
}

div#SeatMap .seat.seat-unavailable,
div#SeatLegend .seat.seat-unavailable {
    border: 1px solid #ccc;
    background-image: url(../../images/seat_sold.png);
    background-repeat: no-repeat;
}

div#SeatMap .seat.seat-selected,
div#SeatLegend .seat.seat-selected {
    background-image: url(../../images/seat_chosen.png);
    background-repeat: no-repeat;
}

div#SeatMap .seat.seat-pending {
    background-image: url(../../images/loading.gif);
    background-repeat: no-repeat;
}

div#SeatMap .row-label {
    position: absolute;
    height: 20px;
    width: 25px;
    text-align: center;
    font-size: 10px;
    line-height: 10px;
}
