/* Different placement of fulfillment event instances on desktop vs mobile */

@media (max-width: 767.98px) {
    .fulfillment #MiniCartVenueInstances {
        display: none;
    }
}

@media (min-width: 768px) {
    .fulfillment #VenueMapInstances {
        display: none;
    }
}

/* Give staged seats a shaded appearance to differentiate from persisted fulfillment items */
.seat.seat-staged {
    opacity: 0.5;
}

.mini-cart-needs-selection {
    color: #7b5a19;
    background-color: #fff1c9;
}