.pts-icon--spin {
	animation-name: spin;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}

.pts-icon--remove {
	transition: 0.2s color ease;
}
.pts-icon--remove:hover {
	color: #c22700; /* Bootstrap danger */
}